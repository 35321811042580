import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError} from "rxjs/internal/operators";

import {Helpers} from "../../helpers";


@Injectable()
export class LanguageService {

    langSettings : any;

    constructor (private _http: HttpClient ) {}

    getLanguages() {
        return new Promise<string>(resolve => {
            if(!this.langSettings) {
                this._http.get('/api/system/languages').pipe(
                    catchError(Helpers.handleError)
                ).subscribe((res) => {
                    this.langSettings = res;
                     resolve(this.langSettings);
                }, error => {
                });
            } else {
                 resolve(this.langSettings);
            }
        });
    }
}
