import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SystemService} from '../../../../_services/system.service';
import {TranslateService} from '@ngx-translate/core';
import {Helpers} from "../../../../helpers";

@Component({
  selector: 'app-register-employer-step-form',
  templateUrl: './register-employer-step-form.component.html',
  styleUrls: ['./register-employer-step-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterEmployerStepFormComponent implements OnInit, OnChanges {

  registerForm: FormGroup;
  showLoader = false;

  currentStep = 0;
  steps = [
    {state: 'active'},
    {state: 'todo'},
  ];

  vacanciesOptions = [];

  inputDisabled = false;

  @Input() errors: any;
  @Output() errorsChange = new EventEmitter<any>();
  @Output() submitted = new EventEmitter();
  @Output() closed = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private _systemService: SystemService,
    private _translateService: TranslateService,
    private ref: ChangeDetectorRef
  ) {
  }

  get step1Valid(): boolean {
    return this.registerForm.get('first_name').valid
      && this.registerForm.get('last_name').valid
      && this.registerForm.get('email').valid
      && this.registerForm.get('telephone').valid
      && this.registerForm.get('password').valid;
  }

  previousStep(): void {
    this.steps[this.currentStep].state = 'todo';
    this.steps[this.currentStep - 1].state = 'active';
    this.currentStep--;
  }

  nextStep(): void {
    this.steps[this.currentStep].state = 'complete';
    this.steps[this.currentStep + 1].state = 'active';
    this.currentStep++;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.errors) {
      this.showLoader = false;
      if (this.errors && this.errors.message && this.errors.message.email) {
        this.steps = [
          {state: 'active'},
          {state: 'todo'},
        ];
        this.currentStep = 0;
        this.ref.detectChanges();
        this.registerForm.get('email').setErrors({'backend': true})
      }
    }
  }

  ngOnInit() {
    this.vacanciesOptions = [
      {
        value: 5, label: this._translateService.instant('header.register.vacancy.5')
      },
      {
        value: 10, label: this._translateService.instant('header.register.vacancy.10')
      },
      {
        value: 15, label: this._translateService.instant('header.register.vacancy.15')
      },
      {
        value: 25, label: this._translateService.instant('header.register.vacancy.25')
      },
      {
        value: 50, label: this._translateService.instant('header.register.vacancy.50')
      },
    ];
    this.initForm();
  }

  close(): void {
    this.closed.emit();
  }

  initForm() {
    this.registerForm = this.fb.group({
      first_name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(255)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      terms: new FormControl(false, Validators.requiredTrue),
      vat: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(14)]),
      telephone: new FormControl('', [Validators.required, Validators.minLength(9)]),
      amount_of_vacancies: new FormControl('', Validators.required),
    });
  }

  submit() {
    this.showLoader = true;
    this.submitted.emit(this.registerForm.getRawValue());
  }
}
