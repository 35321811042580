import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {Helpers} from "../../../../helpers";
import { ProfileService } from 'src/app/dashboard/pages/profile/profile.service';
import { GlobalService } from 'src/app/_services/global.service';
import { SweetAlert2Mixins } from 'src/app/_shared/sweetalert2-mixins/sweetalert2-mixins';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-consent',
    templateUrl: './consent.component.html',
    styleUrls: ['./consent.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConsentComponent implements OnInit {

    isLoggedIn: boolean;

    c_recommendation: boolean;
    c_analytics: boolean;
    c_saved: boolean;

    constructor(
        private _profileService: ProfileService,
        private _globalService: GlobalService,
        private _translateService: TranslateService
    ) {
        this.initializeForm();
    }

    ngOnInit() {
        this.isLoggedIn = this._globalService.getLoggedIn();
        this._globalService.loggedInChange$.subscribe(loggedIn => this.isLoggedIn = loggedIn);
    }

    initializeForm() {
        this.c_recommendation = Helpers.getCookie('c_recommendation') === undefined ? true : Helpers.getCookie('c_recommendation') == 'true' ? true : false;
        this.c_analytics = Helpers.getCookie('c_analytics') === undefined ? true : Helpers.getCookie('c_analytics') == 'true' ? true : false;
        this.c_saved = Helpers.getCookie('c_saved') == 'true' ? true : false;
    }

    consentSave = () => {
        Helpers.setCookie('c_recommendation', this.c_recommendation.toString(), 365);
        Helpers.setCookie('c_analytics', this.c_analytics.toString(), 365);
        this.c_saved = true;
        Helpers.setCookie('c_saved', this.c_saved.toString(), 365);

        if (this.isLoggedIn) {
            this.updateProfile({tracking_cookies: this.c_recommendation, analytic_cookies: this.c_analytics});
        }
    };

    updateProfile(data) {
        this._profileService.updateProfile(data, this._globalService.getAppLanguage()).subscribe((resp) => {
            localStorage.setItem('user', JSON.stringify(resp.item));
            this._globalService.changeUserDetails(resp.item);
        }, error => {
            SweetAlert2Mixins.toast.error.fire(this._translateService.instant('sweetalert.error'));
        });
    }

}
