import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LangGuard} from "./_guards/lang.guard";
import {AppComponent} from "./app.component";
import {NotAdminGuard} from "./_guards/not-admin.guard";
import {AdminGuard} from "./_guards/admin.guard";
import { VideoGuard } from './_guards/video.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [LangGuard],
        component: AppComponent

    },
    {
        path: ':lang',
        canActivate: [LangGuard],
        children: [
            {
                path: "dashboard",
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [NotAdminGuard, VideoGuard]
            },
            {
                path: "admin",
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
                canActivate: [AdminGuard, VideoGuard]
            },
            {
                path: "",
                loadChildren: () => import('./main/main.module').then(m => m.MainModule)
            },
            {
                path: "**",
                loadChildren: () => import('./main/main.module').then(m => m.MainModule)
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        LangGuard,
        NotAdminGuard,
        AdminGuard,
        VideoGuard
    ]
})
export class AppRoutingModule {
}