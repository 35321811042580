import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { GlobalService } from 'src/app/_services/global.service';
import { Helpers } from 'src/app/helpers';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-impersonating-flag',
  templateUrl: './impersonating-flag.component.html',
  styleUrls: ['./impersonating-flag.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImpersonatingFlagComponent implements OnInit {

  appLanguage: string;
  impersonatingSubscription: Subscription;
  isImpersonating: boolean = false;

  constructor(
    private _authService: AuthService,
    private _globalService: GlobalService,
    private _router: Router

  ) {
    this.appLanguage = this._globalService.getAppLanguage();
    this.impersonatingSubscription = this._globalService.isImpersonatingChange$.subscribe(
      isImpersonating => this.isImpersonating = isImpersonating
    );
  }

  ngOnInit() {
  }

  leaveImpersonation() {
    this._authService.leaveImpersonation(this.appLanguage).subscribe(
      response => {
        let impersonationData = response.data;
        Helpers.removeImpersonationData(impersonationData);
        this._globalService.changeUserDetails(response.data.persona);
        this._globalService.changeIsImpersonating(false);
        this._router.navigate([`/${this.appLanguage}/admin/users`]);
      },
      error => console.log(error)
    )
  }

}
