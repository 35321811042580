import {
  Component,
  HostListener,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { fuseAnimations } from "../../../_assets/animations";
import { AuthService } from "../../../_services/auth.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GlobalService } from "../../../_services/global.service";
import { Helpers } from "src/app/helpers";
import { debounceTime, distinctUntilChanged } from "rxjs/internal/operators";
import { TranslateService } from "@ngx-translate/core";
import { SweetAlert2Mixins } from "src/app/_shared/sweetalert2-mixins/sweetalert2-mixins";
import { CvService } from "../../../dashboard/pages/profile/cv/cv.service";
import { CompanyService } from "../../../dashboard/pages/company/company.service";
import { ProfileService } from "../../../dashboard/pages/profile/profile.service";
import { IndexService } from "../../../dashboard/pages/index/index.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [fuseAnimations],
})
export class HeaderComponent implements OnInit {
  //Layout
  mobile = false;
  fixed = false;
  isLoggedIn = false;
  isAdmin = false;
  showLogin = false;
  showSignup = false;
  showEmployer = false;
  showEmployee = false;
  showEmployeeSocial = false;
  showVacancies = false;
  isCandidate = true;
  isImpersonating = false;
  showHeaders = false;

  userDetails: any;
  vacancies: any;
  loading = false;
  appLanguage: string;

  registrationForm: FormGroup;
  registrationError: any;

  forgotPasswordForm: FormGroup;
  forgotPassword = false;
  forgotMessage = "";

  loginForm: FormGroup;
  loginError: string;

  searchForm: FormGroup;
  formSubmitted: boolean;

  registerError: any;

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _fb: FormBuilder,
    private _globalService: GlobalService,
    private _translateService: TranslateService,
    private _cvService: CvService,
    private _profileService: ProfileService,
    private _companyService: CompanyService,
    private _vacancyService: IndexService,
    private renderer: Renderer2
  ) {
    if (
      !this._globalService.getLoggedIn() &&
      !this._globalService.getHasAccount()
    ) {
      this.showHeaders = false;
    }
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll($event) {
    const number = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    this.fixed = number > 80;
  }

  ngOnInit() {
    this.myForms();
    this.mySubscriptions();
  }

  myForms = () => {
    this.forgotPasswordForm = this._fb.group({
      email: new FormControl(localStorage.getItem("rbLogin") || "", [
        Validators.required,
        Validators.email,
      ]),
    });

    this.registrationForm = this._fb.group({
      firstname: new FormControl("", [
        Validators.required,
        Validators.maxLength(60),
      ]),
      lastname: new FormControl("", [
        Validators.required,
        Validators.maxLength(60),
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.email,
        Validators.maxLength(255),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
      gender: new FormControl("0", [
        Validators.required,
        Validators.maxLength(1),
      ]),
      company: new FormControl(""),
      terms: new FormControl(false, Validators.requiredTrue),
    });

    this.loginForm = this._fb.group({
      email: new FormControl(localStorage.getItem("rbLogin") || "", [
        Validators.required,
        Validators.email,
        Validators.maxLength(60),
      ]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
      remember: new FormControl(localStorage.getItem("rbLogin") ? true : false),
    });

    this.searchForm = this._fb.group({
      query: new FormControl("", [Validators.maxLength(50)]),
    });

    this.searchForm
      .get("query")
      .valueChanges.pipe(debounceTime(150), distinctUntilChanged())
      .subscribe((res) => {
        this.searchVacancies();
      });
  };

  mySubscriptions = () => {
    this._globalService.userDetailsChanges$.subscribe(
      (userDetails) => (this.userDetails = userDetails)
    );
    this._globalService.isAdminChange$.subscribe(
      (isAdmin) => (this.isAdmin = isAdmin)
    );
    this._globalService.loggedInChange$.subscribe((loggedIn) => {
      this.isLoggedIn = loggedIn;
      this.renderHeaders(
        this._globalService.getLoggedIn(),
        this._globalService.getHasAccount()
      );
    });
    this._globalService.appLanguageChange$.subscribe(
      (language) => (this.appLanguage = language)
    );
    this._globalService.showLoginChange$.subscribe((showLogin) => {
      this.showLogin = showLogin;
      this.renderModalDom(this.showLogin);
    });
    this._globalService.showEmployeeChange$.subscribe((showEmployee) => {
      this.showEmployee = showEmployee;
      this.renderModalDom(this.showEmployee);
    });
    this._globalService.showEmployeeSocialChange$.subscribe(
      (showEmployeeSocial) => {
        this.showEmployeeSocial = showEmployeeSocial;
        this.renderModalDom(this.showEmployeeSocial);
      }
    );
    this._globalService.showEmployerChange$.subscribe((showEmployer) => {
      this.showEmployer = showEmployer;
      this.renderModalDom(this.showEmployer);
    });
    this._globalService.showSignUpChange$.subscribe((showSignup) => {
      this.showSignup = showSignup;
      this.renderModalDom(this.showSignup);
    });
    this._globalService.isImpersonatingChange$.subscribe(
      (isImpersonating) => (this.isImpersonating = isImpersonating)
    );
  };

  renderHeaders = (loggedin, hasaccount) => {
    // !loggedin && !hasaccount ? this.showHeaders = false : this.showHeaders = true;
    this.showHeaders = true;
  };

  renderModalDom = (open) => {
    if (open) {
      this.renderer.addClass(document.body, "modal-open");
    } else {
      this.renderer.removeClass(document.body, "modal-open");
    }
  };

  toggleLogin = () => {
    this.showLogin = !this.showLogin;
    this.renderModalDom(this.showLogin);
  };

  toggleSignup = () => {
    this.showSignup = !this.showSignup;
    this.renderModalDom(this.showSignup);
  };

  toggleEmployee = () => {
    this.showEmployee = !this.showEmployee;
    this._globalService.changeShowEmployee(this.showEmployee);
  };

  toggleEmployeeSocial = () => {
    this.showEmployeeSocial = !this.showEmployeeSocial;
    this._globalService.changeShowEmployeeSocial(this.showEmployeeSocial);
  };

  toggleEmployer = () => {
    this.showEmployer = !this.showEmployer;
    this._globalService.changeShowEmployer(this.showEmployer);
  };

  toggleForgotPassword = () => {
    this.forgotPassword = !this.forgotPassword;
  };

  toggleCandidate = () => {
    this.isCandidate = !this.isCandidate;
    const companyControl = this.registrationForm.get("company");
    if (this.isCandidate) {
      companyControl.clearValidators();
    } else {
      companyControl.setValidators([
        Validators.required,
        Validators.maxLength(60),
      ]);
    }
    companyControl.updateValueAndValidity();
  };

  toggleHeader = () => {
    this.mobile = !this.mobile;
  };

  register = (value) => {
    this.formSubmitted = true;
    const data = {
      email: value.email,
      first_name: value.firstname,
      last_name: value.lastname,
      gender: value.gender,
      password: value.password,
      mobile: value.mobile ? value.mobile.replace(/ /g, "") : value.phone,
      type: this.showEmployee ? 0 : 1,
    };

    if (this.showEmployer) {
      data["name"] = value.company;
    }

    this.loading = true;
    this.registrationError = null;

    this._auth.register(data).subscribe(
      (resp) => {
        if (!localStorage.getItem("hasAccount")) {
          localStorage.setItem("hasAccount", JSON.stringify(true));
        }
        localStorage.setItem("access_token", resp.access_token);
        localStorage.setItem("user", JSON.stringify(resp.user));
        localStorage.setItem(
          "permissions",
          JSON.stringify(resp.permissions ? resp.permissions : {})
        );
        this._globalService.changeUserDetails(resp.user);
        this._globalService.changeLoggedIn(true);
        // this.toggleSignup();
        this.loading = false;
        this.formSubmitted = false;
        //this._globalService.changeShowSignUp(false);
      },
      (error) => {
        if (error.title && error.title === "Validation error") {
          this.registerError = error;
          /*        for (let control in error.message) {
                  this.registrationForm.get(control).setErrors({'message': error.message[control][0]});
                }*/
          SweetAlert2Mixins.toast.error.fire(
            this._translateService.instant("sweetalert.invalid_form")
          );
        } else {
          SweetAlert2Mixins.toast.error.fire(
            this._translateService.instant("sweetalert.error")
          );
        }
        this.loading = false;
      }
    );
  };

  login = () => {
    this.loading = true;
    this.loginError = null;
    this._auth.login(this.loginForm.value).subscribe(
      (resp) => {
        if (!localStorage.getItem("hasAccount")) {
          localStorage.setItem("hasAccount", JSON.stringify(true));
        }
        localStorage.setItem("access_token", resp.access_token);
        localStorage.setItem("user", JSON.stringify(resp.user));
        localStorage.setItem("permissions", JSON.stringify(resp.permissions));
        this._globalService.changeUserDetails(resp.user);
        this._globalService.changeLoggedIn(true);
        this.updateCookies(resp.user);
        if (this.loginForm.value.remember) {
          localStorage.setItem("rbLogin", this.loginForm.value.email);
        } else {
          if (localStorage.getItem("rbLogin")) {
            localStorage.removeItem("rbLogin");
          }
        }
        this.toggleLogin();
        this.loading = false;
        if (resp.user.type === "jobseeker") {
          this._auth.hasUserCv().subscribe((result) => {
            if (!result) {
              this._router.navigate([this._globalService.getAppLanguage() + '/dashboard/job-offers/cvs']);
            }
          });
        }

        if (
          this._router.url === `/${this.appLanguage}/login` ||
          this._router.url === `/${this.appLanguage}/registreer`
        ) {
          this._router.navigate(["/"]);
        }
        this.loginForm.controls["password"].reset();
      },
      (error) => {
        this.loginError = error.message;
        this.loading = false;
      }
    );
  };

  logout = () => {
    this._auth.logout().subscribe(
      (resp) => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        localStorage.removeItem("permissions");
        this._globalService.changeLoggedIn(false);
        this._router.navigate(["/"]);
      },
      (error) => {}
    );
  };

  requestPassword = () => {
    this.loading = true;
    this._auth.forgotPass(this.forgotPasswordForm.value).subscribe(
      (resp) => {
        this.loading = false;
        this.forgotMessage = resp.message;
      },
      (error) => {
        this.loading = false;
      }
    );
  };

  leaveImpersonation() {
    this._auth.leaveImpersonation(this.appLanguage).subscribe(
      (response) => {
        const impersonationData = response.data;
        Helpers.removeImpersonationData(impersonationData);
        this._globalService.changeUserDetails(response.data.persona);
        this._globalService.changeIsImpersonating(false);
        this._router.navigate([`/${this.appLanguage}/admin/users`]);
      },
      (error) => console.log(error)
    );
  }

  searchVacancies = () => {
    const searchPreferences = {
      query: "",
    };
    if (this.searchForm.value.query) {
      searchPreferences.query = this.searchForm.value.query;
      localStorage.setItem(
        "searchPreferences",
        JSON.stringify(searchPreferences)
      );
    }
    this._router.navigate([
      "/" +
        this.appLanguage +
        "/" +
        this._translateService.instant("routes.vacancies.vacancies"),
    ]);
    this.searchForm.reset();
  };

  updateCookies(userData) {
    const c_recommendation = userData.tracking_cookies ? true : false;
    const c_analytics = userData.analytic_cookies ? true : false;
    Helpers.setCookie("c_recommendation", c_recommendation.toString(), 365);
    Helpers.setCookie("c_analytics", c_analytics.toString(), 365);
  }

  registerEmployee(data): void {
    data.type = 0;

    this.loading = true;
    this.registrationError = null;

    this._auth.register(data).subscribe(
      (resp) => {
        if (!localStorage.getItem("hasAccount")) {
          localStorage.setItem("hasAccount", JSON.stringify(true));
        }
        localStorage.setItem("access_token", resp.access_token);
        localStorage.setItem("user", JSON.stringify(resp.user));
        localStorage.setItem(
          "permissions",
          JSON.stringify(resp.permissions ? resp.permissions : {})
        );
        this._globalService.changeUserDetails(resp.user);
        this._globalService.changeLoggedIn(true);
        this._auth.hasUserCv().subscribe((result) => {
          if (!result) {
            this._router.navigate([this._globalService.getAppLanguage() + '/dashboard/job-offers/cvs']);
          }
        });

        // this._vacancyService.getVacancies().subscribe((vacancies) => {
        //   if (vacancies.count > 0) {
        //     this.vacancies = vacancies.rows;
        //     this.showVacancies = true;
        //   }
        //   this.loading = false;
        //   this.toggleEmployee();
        // });
      },
      (error) => {
        if (error.title && error.title === "Validation error") {
          this.registerError = error;
          SweetAlert2Mixins.toast.error.fire(
            this._translateService.instant("sweetalert.invalid_form")
          );
        } else {
          SweetAlert2Mixins.toast.error.fire(
            this._translateService.instant("sweetalert.error")
          );
        }
        this.loading = false;
      }
    );
  }

  registerEmployer(data): void {
    data.type = 1;

    this.loading = true;
    this.registrationError = null;

    this._auth.register(data).subscribe(
      (resp) => {
        if (!localStorage.getItem("hasAccount")) {
          localStorage.setItem("hasAccount", JSON.stringify(true));
        }
        localStorage.setItem("access_token", resp.access_token);
        localStorage.setItem("user", JSON.stringify(resp.user));
        localStorage.setItem(
          "permissions",
          JSON.stringify(resp.permissions ? resp.permissions : {})
        );
        this._globalService.changeUserDetails(resp.user);
        this._globalService.changeLoggedIn(true);
        this.loading = false;
        this.toggleEmployer();
        this._router
          .navigate(["./" + this._translateService.currentLang + "/prijzen"])
          .then();
      },
      (error) => {
        if (error.title && error.title === "Validation error") {
          this.registerError = error;
          SweetAlert2Mixins.toast.error.fire(
            this._translateService.instant("sweetalert.invalid_form")
          );
        } else {
          SweetAlert2Mixins.toast.error.fire(
            this._translateService.instant("sweetalert.error")
          );
        }
        this.loading = false;
      }
    );
  }

  updateCV(data) {
    this._profileService
      .updateProfile({ mobile: data.mobile })
      .subscribe((response) => {
        this._cvService.updateCV(data).subscribe((response2) => {
          this._vacancyService.getVacancies().subscribe((vacancies) => {
            if (vacancies.count > 0) {
              this.vacancies = vacancies.rows;
              this.showVacancies = true;
            }
            this.toggleEmployeeSocial();
          });
        });
      });
  }

  /*  updateEmployer(data) {
      data.vat = data.vat.toString();
      data.telephone = data.telephone.replace(/ /g, '');
      this._companyService.updateCompany(data).subscribe(response => {
        if (+data.amount_of_vacancies > 5) {
          this._router.navigate(['/' + this.appLanguage + '/prijzen'], {queryParams: {bundles: 1}});
        } else {
          this._router.navigate(['/' + this.appLanguage + '/prijzen']);
        }
        this.toggleEmployer();
      });
    }*/

  apply = (slug) => {
    this._vacancyService.apply(slug).subscribe((response) => {
      SweetAlert2Mixins.toast.success.fire("Succesvol gesolliciteerd!");
      const vacancy = this.vacancies.find((vacancy) => vacancy.slug === slug);
      if (vacancy) {
        vacancy.applied = true;
      }
    });
  };

  closeVacancies = () => {
    this.showVacancies = false;
    this.vacancies = null;
  };
}
