import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {MasterComponent} from './master/master.component';
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { SharedModule } from 'src/app/_shared/shared/shared.module';
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import {StatsFilterComponent} from "../shared/stats-filter/stats-filter.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatTooltipModule,
        MatMenuModule,
        MatButtonModule
    ],
    declarations: [HeaderComponent, FooterComponent, MasterComponent, StatsFilterComponent],
    exports: [
        MasterComponent,
        HeaderComponent,
        StatsFilterComponent,
        FooterComponent,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule
    ]
})
export class LayoutModule {
}
