import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackBtnComponent } from './components/back-btn/back-btn.component';
import { RouterModule } from '@angular/router';
import { MapToIterablePipe } from './pipes/map-to-iterable.pipe';
import { ImpersonatingFlagComponent } from './components/impersonating-flag/impersonating-flag.component';
import { CircularProgressBarComponent } from './components/circular-progress-bar/circular-progress-bar.component';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { MultilineEllipsisDirective } from './directives/multiline-ellipsis.directive';
import { SafePipe } from './pipes/safe.pipe';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import {TranslateModule} from "@ngx-translate/core";
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { MobileMaskDirective } from './directives/mobile-mask.directive';
import { AutofocusDirective } from './directives/autofocus.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [
    BackBtnComponent,
    MapToIterablePipe,
    ImpersonatingFlagComponent,
    CircularProgressBarComponent,
    StripHtmlPipe,
    TruncatePipe,
    ClickOutsideDirective,
    MultilineEllipsisDirective,
    SafePipe,
    PhoneMaskDirective,
    MobileMaskDirective,
    AutofocusDirective
  ],
  providers: [],
    exports: [
        BackBtnComponent,
        MapToIterablePipe,
        TranslateModule,
        ImpersonatingFlagComponent,
        CircularProgressBarComponent,
        StripHtmlPipe,
        TruncatePipe,
        ClickOutsideDirective,
        MultilineEllipsisDirective,
        SafePipe,
        PhoneMaskDirective,
        MobileMaskDirective,
        AutofocusDirective
    ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [StripHtmlPipe]
    };
  }

}
