import {AfterViewInit, Component, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router} from "@angular/router";

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root m-page',
    templateUrl: './master.component.html',
    styleUrls: [
        '../../../assets/css/animate.css',
        './master.component.scss',
        '../../assets/demo/base/style.bundle.css',
        '../../assets/vendors/base/vendors.bundle.css',
        '../../assets/custom/font.css',
        '../../../../../node_modules/froala-editor/css/froala_editor.pkgd.min.css',
        '../../../../../node_modules/froala-editor/css/themes/dark.min.css',
        '../../../../../node_modules/@ng-select/ng-select/themes/default.theme.css',
        './themes/dark.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class MasterComponent implements OnInit, AfterViewInit {

    loading: boolean;

    constructor(private _renderer: Renderer2, private _router: Router, private _route: ActivatedRoute) {
        this.loading = false;
        this._renderer.removeAttribute(document.body, 'class');
        let classes = ['m-page--wide', 'm-header--fixed', 'm-header--fixed-mobile', 'm-footer--push', 'm-aside--offcanvas-default'];
        for (let c of classes) {
            this._renderer.addClass(document.body, c)
        }
    }

    ngAfterViewInit() {
        this._router.events.subscribe((event) => {
            if(event instanceof NavigationStart) {
                this.loading = true;
            }
            else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                this.loading = false;
            }
        });
    }

    ngOnInit() {

    }

}
