import swal from 'sweetalert2/dist/sweetalert2.js'

export class SweetAlert2Mixins {

    static toast = {
        success: swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            icon: 'success',
            customClass: {container: 'custom-toastr'},
            background: '#34bfa3'
        }),
        warning: swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            icon: 'warning',
            customClass: {container: 'custom-toastr'},
            background: '#ffb822'
        }),
        error: swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            icon: 'error',
            customClass: {container: 'custom-toastr'},
            background: '#f4516c'
        }),
        info: swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            icon: 'info',
            customClass: {container: 'custom-toastr'},
            background: '#36a3f7'
        })
    }
}
