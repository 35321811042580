import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError} from "rxjs/internal/operators";
import {Helpers} from "../../../helpers";

@Injectable()
export class ProfileService {

    constructor(private _http: HttpClient) {
    }

    updatePassword = (data, language?: string) => {
        let url = '/api/dashboard/account/password';
        if (language) {
            url += '/' + language;
        }
        return this._http.request<any>('PUT', url, {body: data}).pipe(
            catchError(Helpers.handleError)
        );
    };

    updateProfile = (data, language?: string) => {
        let url = '/api/dashboard/account';
        if (language) {
            url += '/' + language;
        }
        return this._http.request<any>('PUT', url, {body: data}).pipe(
            catchError(Helpers.handleError)
        );
    };

    deleteProfile = () => {
        return this._http.request<any>('DELETE', '/api/dashboard/account', {body: {force: true}}).pipe(
            catchError(Helpers.handleError)
        );
    }
}