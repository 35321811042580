import {BrowserModule, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgModule, Injectable, ErrorHandler} from '@angular/core';
import {MainModule} from "./main/main.module";
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AuthInterceptor} from "./_interceptors/auth-interceptor";
import {Globals} from "./_globals/globals";
import { NgSelectModule } from '@ng-select/ng-select';
import {SystemService} from "./_services/system.service";
import {AppComponent} from './app.component';
import {RouterModule, Routes} from "@angular/router";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {DashboardModule} from "./dashboard/dashboard.module";
import {AppRoutingModule} from "./app-routing.module";
import {GlobalService} from "./_services/global.service";
import * as Sentry from "@sentry/browser";
import { SharedModule } from './_shared/shared/shared.module';
import {environment} from "../environments/environment";
import {CanDeactivateGuard} from "./_guards/can-deactivate.guard";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";

if (environment.production || environment.testing) {
    Sentry.init({
        dsn: 'https://f6d0cc3f46f3408698730c2ed93141dc@sentry.cyrextech.net/2'
    });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor(private _globalService: GlobalService) {}
    handleError(error) {
        const user = this._globalService.getUserDetails();
        if (user) {
            Sentry.withScope(scope => {
                scope.setUser({'email': user.email, 'id': user.id});
                Sentry.captureException(error.originalError || error);
            });
        } else {
            Sentry.captureException(error.originalError || error);
        }
        throw error;
    }
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'bouwjobs'}),
        BrowserAnimationsModule,
        NgSelectModule,
        AppRoutingModule,
        MainModule,
        DashboardModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SweetAlert2Module.forRoot(),
        SharedModule.forRoot()
    ],
    exports: [RouterModule, NgSelectModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        Globals,
        GlobalService,
        SystemService,
        CanDeactivateGuard,
        Title
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
