import {Inject, Injectable, OnInit, PLATFORM_ID} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {GlobalService} from "../_services/global.service";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class VideoGuard implements CanActivate, OnInit {

    constructor(private _router: Router, private _globalService: GlobalService, @Inject(PLATFORM_ID) private platform: Object) {
    }

    ngOnInit() {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        /*if (this._globalService.getHasSeenVideo() || !isPlatformBrowser(this.platform)) {
            return true;
        } else {
            this._globalService.changeStoredUrl(state.url);
            this._router.navigate(['/' + this._globalService.getAppLanguage() + '/video']);
        }*/
        return true;
    }
}
