import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';

import {MainRoutingModule} from "./routing/main-routing.module";
import {LayoutModule} from "./layout/layout.module";
import {LanguageService} from "./_services/lang.service";
import {AuthService} from "../_services/auth.service";
import {BookmarkService} from "./_services/bookmark.service";
import {ShareButtonsModule} from "ngx-sharebuttons/buttons";
import {ShareIconsModule} from "ngx-sharebuttons/icons";


@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    MainRoutingModule,
    ShareButtonsModule,
    ShareIconsModule
  ],
  declarations: [MainComponent],
  providers: [
      LanguageService,
      AuthService,
      BookmarkService
  ]
})
export class MainModule { }
