import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError} from "rxjs/internal/operators";
import {Helpers} from "../../../../helpers";

@Injectable()
export class CvService {

    url: string;

    constructor(private _http: HttpClient) {
    }

    getCV = (language?: string) => {
        this.url = '/api/dashboard/jobseeker/cv';
        if(language) {
            this.url += '/' + language;
        }
        return this._http.request<any>('GET', this.url).pipe(
            catchError(Helpers.handleError)
        )
    }

    updateCV = (data: any, language?:string) => {
        this.url = '/api/dashboard/jobseeker/cv';
        if(language) {
            this.url += '/' + language;
        }
        return this._http.request<any>('PUT', this.url, {body: data}).pipe(
            catchError(Helpers.handleError)
        )
    }

    downloadCV = (language: string) => {
        this.url = `/api/dashboard/jobseeker/cv/download/${language}`;
        return this._http.request<any>('GET', this.url, { responseType: 'blob' as 'json' }).pipe(
            catchError(Helpers.handleError)
        )
    }

}