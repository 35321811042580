import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from 'rxjs'


export class Helpers {

  static handleError(err: HttpErrorResponse) {
    return throwError(err);
  }

  static errorMessage(message) {

  }

  /*
* General utils for managing cookies in Typescript.
*/
  static setCookie(name: string, val: string, time?: number) {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + (time * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
  }

  static getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
      return parts.pop().split(";").shift();
    }
  }

  static deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
  }

  static b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  static getCurrentDate = () => {
    let today: any = new Date();
    var dd = today.getDate();
    let mm: any = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }

  static updateLocalStorage = (objectName, propertyName, newValue) => {
    let object = JSON.parse(localStorage.getItem(objectName));
    if (object[propertyName] !== newValue) {
      object[propertyName] = newValue;
      localStorage.setItem(objectName, JSON.stringify(object));
    }
    return object;
  }

  static setImpersonationData = (impersonationData) => {
    localStorage.setItem('permissions', JSON.stringify(impersonationData.permissions));
    localStorage.setItem('user', JSON.stringify(impersonationData.persona));
    localStorage.setItem('access_token', impersonationData.token);
    localStorage.setItem('impersonating', 'true');
  }

  static removeImpersonationData = (impersonationData) => {
    localStorage.setItem('permissions', JSON.stringify(impersonationData.permissions));
    localStorage.setItem('user', JSON.stringify(impersonationData.persona));
    localStorage.setItem('access_token', impersonationData.token);
    localStorage.removeItem('impersonating');
  }

}
