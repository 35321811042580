import {Component, OnDestroy, OnInit, ViewEncapsulation, HostListener} from '@angular/core';
import {GlobalService} from '../../../_services/global.service';
import {Subscription} from 'rxjs/index';
import { Router, Event, NavigationStart } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { Helpers } from 'src/app/helpers';

@Component({
    selector: 'dashboard-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.windowWidth = window.innerWidth;
    }

    appLanguage: any;
    user: any;
    isJobseeker = false;
    userSubscription: Subscription;
    mobileMenuOn = false;
    isImpersonating: boolean;
    isLoggedIn: boolean;
    windowWidth: number;

    constructor (
        private _globalService: GlobalService,
        private _router: Router,
        private _auth: AuthService
    ) {
        this.onResize();
        this.appLanguage = this._globalService.getAppLanguage();
    }

    ngOnInit() {
        this.mySubscriptions();
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    mySubscriptions = () => {
        this.userSubscription = this._globalService.userDetailsChanges$.subscribe( userDetails => {
            this.user = userDetails;
        });
        this._globalService.isJobSeekerChange$.subscribe(isJobseeker => this.isJobseeker = isJobseeker);
        this._globalService.loggedInChange$.subscribe(loggedIn => this.isLoggedIn = loggedIn);
        this._globalService.isImpersonatingChange$.subscribe(
            isImpersonating => this.isImpersonating = isImpersonating);
    }

    logout = () => {
        this._auth.logout().subscribe((resp) => {
            this._globalService.changeLoggedIn(false);
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
            localStorage.removeItem('permissions');
            this._router.navigate(['/']);
        }, error => {
        });
    }

    leaveImpersonation() {
        this._auth.leaveImpersonation(this.appLanguage).subscribe(
            response => {
                const impersonationData = response.data;
                Helpers.removeImpersonationData(impersonationData);
                this._globalService.changeUserDetails(response.data.persona);
                this._globalService.changeIsImpersonating(false);
                this._router.navigate([`/${this.appLanguage}/admin/users`]);
            },
            error => console.log(error)
        );
    }

}
