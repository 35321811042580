import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/internal/operators";

import { Helpers } from "../helpers";

@Injectable()
export class SystemService {
  vacanciesContractTypes: any;
  vacanciesJobTypes: any;
  vacanciesJobFunctions: any;
  vacanciesExperienceRanges: any;
  vacanciesEducations: any;
  articlesCategories: any;
  competencies: any;
  companySectors: any;
  onlineSinceRanges: any;
  url: any;

  constructor(private _http: HttpClient) {}

  getVacanciesContractTypes = (language?: string) => {
    return new Promise<string>((resolve) => {
      if (!this.vacanciesContractTypes) {
        this.url = "/api/system/contract_types";
        if (language) {
          this.url += "/" + language;
        }
        this._http
          .request<any>("GET", this.url)
          .pipe(catchError(Helpers.handleError))
          .subscribe((resp) => {
            this.vacanciesContractTypes = resp;
            resolve(this.vacanciesContractTypes);
          });
      } else {
        resolve(this.vacanciesContractTypes);
      }
    });
  };

  getVacanciesJobTypes = (language?: string) => {
    return new Promise<string>((resolve) => {
      if (!this.vacanciesJobTypes) {
        this.url = "/api/system/job_types";
        if (language) {
          this.url += "/" + language;
        }
        this._http
          .request<any>("GET", this.url)
          .pipe(catchError(Helpers.handleError))
          .subscribe((resp) => {
            this.vacanciesJobTypes = resp;
            resolve(this.vacanciesJobTypes);
          });
      } else {
        resolve(this.vacanciesJobTypes);
      }
    });
  };

  getCompetencies = (language?: string) => {
    return new Promise<string>((resolve) => {
      if (!this.competencies) {
        this.url = "/api/system/competencies";
        if (language) {
          this.url += "/" + language;
        }
        this._http
          .get(this.url)
          .pipe(catchError(Helpers.handleError))
          .subscribe(
            (res) => {
              this.competencies = res;
              resolve(this.competencies);
            },
            (error) => {}
          );
      } else {
        resolve(this.competencies);
      }
    });
  };

  getCompetences = (searchTerm?: string) => {
    this.url = "/api/search-profile/competences";

    if (searchTerm) {
      this.url += `?search=${searchTerm}`;
    }
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  getProfileSearchJobFunctions = (searchTerm?: string) => {
    this.url = "/api/search-profile/job-functions";

    if (searchTerm) {
      this.url += `?search=${searchTerm}`;
    }
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  getVacanciesJobFunctions = (language?: string) => {
    return new Promise<string>((resolve) => {
      if (!this.vacanciesJobFunctions) {
        this.url = "/api/system/job_functions";
        if (language) {
          this.url += "/" + language;
        }
        this._http
          .request<any>("GET", this.url)
          .pipe(catchError(Helpers.handleError))
          .subscribe((resp) => {
            this.vacanciesJobFunctions = resp;
            resolve(this.vacanciesJobFunctions);
          });
      } else {
        resolve(this.vacanciesJobFunctions);
      }
    });
  };

  getVacanciesExperienceRanges = (language?: string) => {
    return new Promise<string>((resolve) => {
      if (!this.vacanciesExperienceRanges) {
        this.url = "/api/system/experience_ranges";
        if (language) {
          this.url += "/" + language;
        }
        this._http
          .request<any>("GET", this.url)
          .pipe(catchError(Helpers.handleError))
          .subscribe((resp) => {
            this.vacanciesExperienceRanges = resp;
            resolve(this.vacanciesExperienceRanges);
          });
      } else {
        resolve(this.vacanciesExperienceRanges);
      }
    });
  };

  getVacanciesEducations = (language?: string) => {
    return new Promise<string>((resolve) => {
      if (!this.vacanciesEducations) {
        this.url = "/api/system/education";
        if (language) {
          this.url += "/" + language;
        }
        this._http
          .request<any>("GET", this.url)
          .pipe(catchError(Helpers.handleError))
          .subscribe((resp) => {
            this.vacanciesEducations = resp;
            resolve(this.vacanciesEducations);
          });
      } else {
        resolve(this.vacanciesEducations);
      }
    });
  };

  getArticlesCategories = (language?: string, sub?: string) => {
    return new Promise<string>((resolve) => {
      this.url = "/api/system/categories";
      if (language) {
        this.url += "/" + language;
      }
      if (sub && sub != "") {
        this.url += "?category=" + sub;
      }
      this._http
        .request<any>("GET", this.url)
        .pipe(catchError(Helpers.handleError))
        .subscribe((resp) => {
          this.articlesCategories = resp;
          resolve(this.articlesCategories);
        });
    });
  };

  getArticlesTags = (language?: string) => {
    this.url = "/api/system/tags";
    if (language) {
      this.url += "/" + language;
    }
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  getTopArticlesTags = (language?: string, tagId?: string, sub?: string) => {
    this.url = "/api/system/tags_top";
    if (language) {
      this.url += "/" + language;
    }
    if (tagId && tagId != "") {
      this.url += "?include=" + tagId;
    }
    if (sub) {
      this.url += "?category=" + sub;
    }
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  getCompanySectors = (language?: string) => {
    return new Promise<string>((resolve) => {
      if (!this.companySectors) {
        this.url = "/api/system/sectors";
        if (language) {
          this.url += "/" + language;
        }
        this._http
          .request<any>("GET", this.url)
          .pipe(catchError(Helpers.handleError))
          .subscribe((resp) => {
            this.companySectors = resp;
            resolve(this.companySectors);
          });
      } else {
        resolve(this.companySectors);
      }
    });
  };

  getDriversLicenses = (language?: string) => {
    this.url = "/api/system/drivers_licences";
    if (language) {
      this.url += "/" + language;
    }
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  getCertificates = (language?: string) => {
    this.url = "/api/system/certificates";
    if (language) {
      this.url += "/" + language;
    }
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  getLocations = (query) => {
    this.url = "/api/system/regions/" + query;
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  searchCity = (query) => {
    this.url = "/api/system/regions/" + query + "?type=city";
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  searchProvince = (query) => {
    this.url = "/api/system/regions/" + query + "?type=province";
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  getProvinces = (query?) => {
    const filterValue = query || '';
    this.url = "/api/search-profile/locations";
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError), map(result => result.provinces.filter(option => option.name.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue) || option.id === parseInt(filterValue))))
  };

  getCities = (query?) => {
    const filterValue = query || '';
    this.url = "/api/search-profile/locations";
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError), map(result => result.cities.filter(option => option.name.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue))))
  };

  searchCountry = (query) => {
    this.url = "/api/system/regions/" + query + "?type=country";
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  searchCompany = (query) => {
    this.url = "/api/system/companies/" + query;
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  getRegionParents = (query) => {
    this.url = "/api/system/region_data/" + query;
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  getPackages = (language: string) => {
    this.url = "/api/system/packages";
    if (language) {
      this.url += "/" + language;
    }
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  getSingles = (language: string) => {
    this.url = "/api/system/singles";
    if (language) {
      this.url += "/" + language;
    }
    return this._http
      .request<any>("GET", this.url)
      .pipe(catchError(Helpers.handleError));
  };

  getOnlineSinceRanges = (language?: string) => {
    return new Promise<string>((resolve) => {
      if (!this.onlineSinceRanges) {
        this.url = "/api/system/online_since";
        if (language) {
          this.url += "/" + language;
        }
        this._http
          .request<any>("GET", this.url)
          .pipe(catchError(Helpers.handleError))
          .subscribe((resp) => {
            this.onlineSinceRanges = resp;
            resolve(this.onlineSinceRanges);
          });
      } else {
        resolve(this.onlineSinceRanges);
      }
    });
  };
}
