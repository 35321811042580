import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, Renderer2, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Globals} from "./_globals/globals";
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {isPlatformBrowser} from "@angular/common";
import {SeoService} from "./_services/seo.service";

declare var ga: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
        './assets/fonts/line-awesome/line-awesome/css/line-awesome.css',
        './assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css',
        './app.component.scss'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'app';

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _renderer: Renderer2,
        private _globals: Globals,
        private _titleService: Title,
        private _metaService: Meta,
        private _translateService: TranslateService,
        private _seoService: SeoService,
        @Inject(PLATFORM_ID) private platform: Object
    ) {
        this._router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (this._router.url.indexOf('dashboard') == 4) {
                    this._renderer.removeClass(document.getElementsByTagName('html')[0], 'admin');
                    this._renderer.removeClass(document.getElementsByTagName('html')[0], 'main');
                    this._renderer.addClass(document.getElementsByTagName('html')[0], 'dashboard');
                    this._titleService.setTitle(`Dashboard - Bouwjobs`);
                } else if (this._router.url.indexOf('admin') == 4) {
                    this._renderer.removeClass(document.getElementsByTagName('html')[0], 'dashboard');
                    this._renderer.removeClass(document.getElementsByTagName('html')[0], 'main');
                    this._renderer.addClass(document.getElementsByTagName('html')[0], 'admin');
                    this._titleService.setTitle(`Administration - Bouwjobs`);
                } else {
                    this._renderer.removeClass(document.getElementsByTagName('html')[0], 'dashboard');
                    this._renderer.removeClass(document.getElementsByTagName('html')[0], 'admin');
                    this._renderer.addClass(document.getElementsByTagName('html')[0], 'main');
                }
                window.scrollTo(0, 0);
            }
        });
    }

    ngAfterViewInit(): void {
        this._router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (isPlatformBrowser(this.platform) && typeof ga === 'function') {
                    ga('set', 'page', val.urlAfterRedirects);
                    ga('send', 'pageview');
                }
            }
        });
    }

    ngOnInit() {
        if (sessionStorage) {
            this.initSessionStorage(['searchPreferences', 'articleSearchPreferences', 'companySearchPreferences'])
        }
        this._globals.setReferrer(document.referrer);
        this._seoService.createLinkForCanonicalURL();


      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      // We listen to the resize event
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }

    initSessionStorage = (items) => {
        for (let item of items) {
            if (!sessionStorage.getItem(item))
                sessionStorage.setItem(item, JSON.stringify({}));
        }
    }


}
