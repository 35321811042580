import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {DashboardRoutingModule} from './routing/dashboard-routing.module';
import {LayoutModule} from './layout/layout.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {AnimateOnScrollModule} from 'ng2-animate-on-scroll';

@NgModule({
    imports: [
        CommonModule,
        LayoutModule,
        DashboardRoutingModule,
        NgbModule,
        AnimateOnScrollModule.forRoot()
    ],
    declarations: [DashboardComponent],
})
export class DashboardModule {
}
