import {Component, HostBinding, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'dashboard-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],

})
export class FooterComponent implements OnInit {
    @HostBinding('class') classes = 'm-grid__item m-footer';

    constructor() {

    }

    ngOnInit() {
    }

}
