import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map} from "rxjs/internal/operators";
import {Helpers} from "../../../helpers";

@Injectable()
export class CompanyService {

    url: string;

    constructor(private _http: HttpClient) {
    }

    getCompany = (language?: string) => {
        let url = '/api/dashboard/company';
        if (language) {
            url += '/' + language;
        }
        return this._http.request<any>('GET', url).pipe(
            catchError(Helpers.handleError)
        );
    };

    updateCompany = (data) => {
        return this._http.request<any>('PUT', '/api/dashboard/company', {body: data}).pipe(
            catchError(Helpers.handleError)
        );
    };

    updateBrand = (data, language?: string) => {
        let url = '/api/dashboard/company/brandpage';
        if (language) {
            url += '/' + language;
        }
        return this._http.request<any>('PUT', url, {body: data}).pipe(
            catchError(Helpers.handleError)
        );
    };

    getSubAccount = (id, language?: string) => {
        let url = `/api/dashboard/company/accounts/id/${id}`;
        if (language) {
            url += '/' + language;
        }
        return this._http.request<any>('GET', url).pipe(
            catchError(Helpers.handleError)
        );
    };

    addSubAccount = (data, language?: string) => {
        let url = '/api/dashboard/company/accounts';
        if (language) {
            url += '/' + language;
        }
        return this._http.request<any>('POST', url, {body: data}).pipe(
            catchError(Helpers.handleError)
        );
    };

    editSubAccount = (data, language?: string) => {
        let url = '/api/dashboard/company/accounts';
        if (language) {
            url += '/' + language;
        }
        return this._http.request<any>('PUT', url, {body: data}).pipe(
            catchError(Helpers.handleError)
        );
    }
}