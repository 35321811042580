import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'stats-filter',
    templateUrl: './stats-filter.component.html',
    styleUrls: ['./stats-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StatsFilterComponent implements OnInit {

    months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    currentMonth: string;

    @Input() filterData: any;
    @Output() toggleFilter = new EventEmitter<any>();
    constructor() {
    }

    ngOnInit() {
        if (this.filterData.month) {
           this.currentMonth = this.months[this.filterData.month - 1];
        }
    }

    removeFilter = () => {
        this.toggleFilter.emit();
    }

}
