import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VideoPopupComponent implements OnInit {

  @Output() videoClosed = new EventEmitter<boolean>();
  
  constructor() { }

  ngOnInit() {
  }

  closePopUp() {
    this.videoClosed.emit(true);
  }
}
