import {Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {Router} from "@angular/router";
import { GlobalService } from 'src/app/_services/global.service';
import {DOCUMENT, isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  host: {
      '[class.dialog-open]' : 'dialogOpen'
  },
  styleUrls: [
      '../../../assets/css/animate.css',
      '../../assets/css/bootstrap-grid.css',
      '../../assets/css/bootstrap.css',
      '../../assets/css/style.css',
      '../../assets/css/responsive.css',
      '../../assets/css/chosen.css',
      '../../assets/css/colors.css',
      '../../main.component.css',
      '../../assets/fonts/line-awesome/line-awesome/css/line-awesome.css',
      '../../assets/fonts/font-awesome-4.7.0/css/font-awesome.css',
      '../../assets/custom/font.css',
      '../../../../../node_modules/froala-editor/css/froala_style.min.css',
      '../../../../../node_modules/hover.css/scss/hover.scss',
      './master.component.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class MasterComponent implements OnInit {

    hasSeenVideo: boolean;
    isBrowser: boolean;
    isScrolled: boolean = false;
    dialogOpen : boolean = false;


    scrollToTop = () => {
        window.scrollTo(0, 0);
    };


    @HostListener('window:scroll', [])
    onWindowScroll() {
        const top = window.pageYOffset || this._document.documentElement.scrollTop || this._document.body.scrollTop || 0;
        if (top > 150) {
            this.isScrolled = true;
        } else if (this.isScrolled && top < 10) {
            this.isScrolled = false;
        }
    }

    constructor(private _router: Router, private _globalService: GlobalService, @Inject(PLATFORM_ID) private platform: Object, @Inject(DOCUMENT) private _document: any) {
        /*this._router.routeReuseStrategy.shouldReuseRoute = function() {
            return false;
        };*/
        this.isBrowser = isPlatformBrowser(this.platform);
    }

    ngOnInit() {
        this.hasSeenVideo = true;
        this.mySubscriptions();
        //this.hasSeenVideo = this._globalService.getHasSeenVideo();
    }

    mySubscriptions = () => {
        this._globalService.openDialogChange$.subscribe(open => this.dialogOpen = open);
    };



    onVideoClosed() {
        this.hasSeenVideo = true;
    }

}
