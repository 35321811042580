import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError} from 'rxjs/internal/operators';

import {Helpers} from '../../../helpers';

@Injectable()
export class FooterService {

    url: string;

    constructor(private _http: HttpClient) {
    }

    getFtCompanies = (language?: string) => {
        this.url = '/api/home/companies';
        if (language) {
            this.url += '/' + language;
        }
        return this._http.request<any>('GET', this.url).pipe(
            catchError(Helpers.handleError)
        );
    }
}
