import { Injectable } from '@angular/core';

@Injectable()
export class Globals {

    referrer: any;

    storage: any = {
        pictures: '/storage/pictures/',
        logos: '/storage/logos/',
        banners: '/storage/banners/'
    }

    getReferrer = () => {
        return this.referrer;
    }

    setReferrer = (value) => {
        this.referrer = value;
    }

}