import {Injectable} from "@angular/core";
import {Helpers} from "../../helpers";
import {catchError} from "rxjs/internal/operators";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class BookmarkService {
    constructor (private _http: HttpClient) {}

    bookmarkVacancy = (vacancy) => {
        let method = (vacancy.wishlist) ? 'DELETE' : 'POST';
        return this._http.request<any>(method, '/api/dashboard/account/wishlist/vacancy/' + vacancy.id, {}).pipe(
            catchError(Helpers.handleError)
        )
    };

    bookmarkArticle = (article) => {
        let method = (article.wishlist) ? 'DELETE' : 'POST';
        return this._http.request<any>(method, '/api/dashboard/account/wishlist/article/' + article.id, {}).pipe(
            catchError(Helpers.handleError)
        )
    };
}