import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
import {MasterComponent} from "../layout/master/master.component";
import {StateGuard} from "../_guards/state.guard";
import { VideoGuard } from 'src/app/_guards/video.guard';


const routes: Routes = [
    {
        path: '',
        component: MasterComponent,
        children: [
            {
                path: "index",
                loadChildren: () => import('../pages/index/index.module').then(m => m.IndexModule),
                canActivate: [VideoGuard]
            },
            {
                path: "vacatures",
                loadChildren: () => import('../pages/vacancies/vacancies.module').then(m => m.VacanciesModule),
                canActivate: [VideoGuard]
            },
            {
                path: "prijzen",
                loadChildren: () => import('../pages/pricing/pricing.module').then(m => m.PricingModule),
                canActivate: [VideoGuard]
            },
            {
                path: "artikels",
                loadChildren: () => import('../pages/articles/articles.module').then(m => m.ArticlesModule),
                canActivate: [VideoGuard]
            },
            {
                path: "bedrijven",
                loadChildren: () => import('../pages/companies/companies.module').then(m => m.CompaniesModule),
                canActivate: [VideoGuard]
            },
            {
                path: "recommendations",
                loadChildren: () => import('../pages/recommendations/recommendations.module').then(m => m.RecommendationsModule),
                canActivate: [VideoGuard]
            },
            {
                path: "wachtwoord-herstel",
                loadChildren: () => import('../pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
                canActivate: [StateGuard, VideoGuard]
            },
            {
                path: "activatie",
                loadChildren: () => import('../pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
                canActivate: [StateGuard, VideoGuard]
            },
            {
                path: "login",
                loadChildren: () => import('../pages/auth/login/login.module').then(m => m.LoginModule),
                canActivate: [StateGuard, VideoGuard]
            },
            {
                path: "registreer",
                loadChildren: () => import('../pages/auth/register/register.module').then(m => m.RegisterModule),
                canActivate: [StateGuard, VideoGuard]
            },
            {
                path: "terms",
                loadChildren: () => import('../pages/terms/terms.module').then(m => m.TermsModule),
                canActivate: [VideoGuard]
            },
            {
                path: "video",
                loadChildren: () => import('../pages/video/video.module').then(m => m.VideoModule)
            },
            {
                path: "notifications/opt-out",
                loadChildren: () => import('../pages/notifications/notifications.module').then(m => m.NotificationsModule),
                canActivate: [VideoGuard]
            },
            {
                path: "subscriptions",
                loadChildren: () => import('../pages/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
            },
            {
                path: "",
                redirectTo: "index",
                pathMatch: "full"
            },
            {
                path: "404",
                loadChildren: () => import('../pages/error/error.module').then(m => m.ErrorModule),
                canActivate: [VideoGuard]
            },
            {
                path: "**",
                redirectTo: "index",
                pathMatch: "full",
            }

        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: [],
    providers: [StateGuard, VideoGuard]
})
export class MainRoutingModule {
}
