import {Inject, Pipe, PipeTransform, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";
@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
  constructor(@Inject(PLATFORM_ID) private platform: Object) {  }

  transform(html: string) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    if(isPlatformBrowser(this.platform))
      return doc.body.textContent || "";
    else
      return doc.firstChild.textContent || "";
  }
}