import {Inject, Injectable} from '@angular/core';
import {DOCUMENT, Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})

export class SeoService {
    private link: HTMLLinkElement;

    constructor(@Inject(DOCUMENT) private doc, private _location: Location) {
    }

    createLinkForCanonicalURL() {
        if (this.link === undefined) {
            this.link = this.doc.createElement('link');
            this.link.setAttribute('rel', 'canonical');
            this.doc.head.appendChild(this.link);
        }
        this.link.setAttribute('href', 'https://www.bouwjobs.be' + this._location.path());
    }
}
