import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from "../_services/auth.service";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class MainComponent implements OnInit {


  constructor(auth: AuthService) {

  }

  ngOnInit() {

  }

}
