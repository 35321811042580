import {Injectable, OnInit} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from "../main/_services/lang.service";
import {GlobalService} from "../_services/global.service";

@Injectable()
export class LangGuard implements CanActivate, OnInit {

    langStore: any;
    langService: any;
    appLanguage: string;


    constructor(private _langService: LanguageService, private translate: TranslateService, private router: Router, private _globalService: GlobalService) {
        this.langService = _langService;
    }

    ngOnInit() {
    }

    defaultRoute = (error?: boolean) => {
        if(error) {
            this.router.navigate(['/' + this.langStore.default])
        } else {
            this.router.navigate(['/' + this.langStore.default])
        }
        this._globalService.changeAppLanguage(this.langStore.default);

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.langService.getLanguages().then(lang => {
            this.translate.setDefaultLang(lang.default);
            this.langStore = lang;
            if((route.children.length && route.children[0].params.lang)){
                if(this.langStore.languages.filter(function(e) { return e.code === route.children[0].params.lang; }).length > 0) {
                    localStorage.setItem('lang', route.children[0].params.lang);
                    this._globalService.changeAppLanguage(route.children[0].params.lang);
                    return true;
                } else {
                    if(localStorage.getItem('lang')) {
                        if(this.langStore.languages.filter(function(e) { return e.code === localStorage.getItem('lang'); }).length > 0) {
                            this.router.navigate(['/' + localStorage.getItem('lang')]);
                            this._globalService.changeAppLanguage(localStorage.getItem('lang'));
                        } else {
                            this.defaultRoute(true);
                        }
                    } else if (navigator.language) {
                        if(this.langStore.languages.filter(function(e) { return e.code === navigator.language.split('-')[0]; }).length > 0) {
                            this.router.navigate(['/' + navigator.language.split('-')[0]]);
                            this._globalService.changeAppLanguage(navigator.language.split('-')[0]);
                        } else {
                            this.defaultRoute(true);
                        }
                    } else {
                        this.router.navigate(['/' + this.langStore.default])
                    }
                }
            } else {
                if(localStorage.getItem('lang')) {
                    if(this.langStore.languages.filter(function(e) { return e.code === localStorage.getItem('lang'); }).length > 0) {
                        this.router.navigate(['/' + localStorage.getItem('lang')]);
                        this._globalService.changeAppLanguage(localStorage.getItem('lang'));
                    } else {
                        this.defaultRoute();
                    }
                } else if (navigator.language) {
                    if(this.langStore.languages.filter(function(e) { return e.code === navigator.language.split('-')[0]; }).length > 0) {
                        this.router.navigate(['/' + navigator.language.split('-')[0]]);
                        this._globalService.changeAppLanguage(navigator.language.split('-')[0]);

                    } else {
                        this.defaultRoute();
                    }
                } else {
                    this.defaultRoute();
                }
            }
            return false;
        })
    }
}