import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { catchError, map } from "rxjs/internal/operators";

import { Helpers } from "../helpers";
import {of, throwError} from "rxjs";

@Injectable()
export class AuthService {
  constructor(private _http: HttpClient) {}

  register = (data) => {
    return this._http
      .request<any>("POST", "/api/auth/register", { body: data })
      .pipe(catchError(Helpers.handleError));
  };

  login = (data) => {
    return this._http
      .request<any>("POST", "/api/auth/login", { body: data })
      .pipe(catchError(Helpers.handleError));
  };

  hasUserCv = () => {
    const url = "/api/search-profile/cvs/user-cv";
    return this._http.get<any>(url).pipe(
      map(() => true),
      catchError((error) => {
        if (error.message === 'CV not found') {
          return of(false);
        }
        return throwError(error);
      })
    );
  };

  logout = () => {
    return this._http
      .request<any>("POST", "/api/auth/logout")
      .pipe(catchError(Helpers.handleError));
  };

  verify = () => {
    return this._http
      .request<any>("POST", "/api/auth/verify")
      .pipe(catchError(Helpers.handleError));
  };

  forgotPass = (data) => {
    return this._http
      .request<any>("POST", "/api/auth/password/email", { body: data })
      .pipe(catchError(Helpers.handleError));
  };

  resetPass = (data) => {
    return this._http
      .request<any>("POST", "/api/auth/password/reset", { body: data })
      .pipe(catchError(Helpers.handleError));
  };

  impersonate = (userId: string, language?: string) => {
    let url = `/api/auth/impersonate/take/${userId}`;
    if (language) {
      url += `/${language}`;
    }
    return this._http
      .request<any>("GET", url)
      .pipe(catchError(Helpers.handleError));
  };

  leaveImpersonation = (language?: string) => {
    let url = `/api/auth/impersonate/leave`;
    if (language) {
      url += `/${language}`;
    }
    return this._http
      .request<any>("GET", url)
      .pipe(catchError(Helpers.handleError));
  };
}
