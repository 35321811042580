import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {SystemService} from '../../../../_services/system.service';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, distinctUntilChanged} from 'rxjs/internal/operators';
import {SweetAlert2Mixins} from '../../../../_shared/sweetalert2-mixins/sweetalert2-mixins';

@Component({
  selector: 'app-register-employee-social-step-form',
  templateUrl: './register-employee-social-step-form.component.html',
  styleUrls: ['./register-employee-social-step-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterEmployeeSocialStepFormComponent implements OnInit {

  registerForm: FormGroup;
  regions: any[];
  regionsInput$ = new Subject<string>();
  showLoader = false;

  currentStep = 0;
  steps = [
    {state: 'active'},
    {state: 'todo'},
    {state: 'todo'},
  ];

  inputDisabled = false;

  @ViewChild('cvInput') cvInput: ElementRef;
  @Input() errors: any;
  @Output() errorsChange = new EventEmitter<any>();
  @Output() submitted = new EventEmitter();
  @Output() closed = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private _systemService: SystemService,
    private _translateService: TranslateService,
  ) {
  }

  get step1Valid(): boolean {
    return this.registerForm.get('mobile').valid;
  }

  get step2Valid(): boolean {
    return this.registerForm.get('job_function').valid
      && this.registerForm.get('region_id').valid;
  }

  previousStep(): void {
    this.steps[this.currentStep].state = 'todo';
    this.steps[this.currentStep - 1].state = 'active';
    this.currentStep--;
  }

  nextStep(): void {
    this.steps[this.currentStep].state = 'complete';
    this.steps[this.currentStep + 1].state = 'active';
    this.currentStep++;
  }

  ngOnInit() {
    this.initForm();
    this.subscribeToRegionsSearch();
  }

  close(): void {
    this.closed.emit();
  }

  fileInputClicked(): void {
    this.cvInput.nativeElement.click();
  }

  initForm() {
    this.registerForm = this.fb.group({
      job_function: new FormControl('', Validators.required),
      mobile: new FormControl('', [Validators.required, Validators.minLength(9)]),
      region_id: new FormControl('', Validators.required),
      file: new FormControl('', Validators.required),
      filename: new FormControl('', Validators.required),
      fileupload: new FormControl('')
    });
  }

  addFile = (fileInput) => {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.registerForm.controls.file.patchValue((reader.result as string).split(',')[1]);
      }, false);

      this.registerForm.controls.filename.patchValue(fileInput.target.files[0].name);

      if (fileInput) {
        reader.readAsDataURL(fileInput.target.files[0]);
      }
    }
  }

  subscribeToRegionsSearch = () => {
    this.regionsInput$.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(query => {
      if (query) {
        this._systemService.getLocations(query).subscribe((resp) => {
          this.regions = resp.result;
        }, error => {
          console.log(error);
          SweetAlert2Mixins.toast.error.fire(this._translateService.instant('sweetalert.error'));
        });
      }
    });
  }

  submit() {
    this.showLoader = true;
    this.submitted.emit(this.registerForm.getRawValue());
  }

}
