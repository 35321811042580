import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {MasterComponent} from "../layout/master/master.component";
import {EmployerGuard} from "../../_guards/employer.guard";
import {PrimaryGuard} from "../../_guards/primary.guard";
import { JobseekerGuard } from 'src/app/_guards/jobseeker.guard';


const routes: Routes = [
    {
        path: '',
        component: MasterComponent,
        children:[
            {
                path: "index",
                loadChildren: () => import('../../dashboard/pages/index/index.module').then(m => m.IndexModule)
            },
            {
                path: "jobs",
                loadChildren: () => import('../../dashboard/pages/jobs/jobs.module').then(m => m.JobsModule)
            },
            {
                path: "applications",
                loadChildren: () => import('../../dashboard/pages/applications/applications.module').then(m => m.ApplicationsModule),
                canActivate: [EmployerGuard]
            },
            {
                path: "bookmarks",
                loadChildren: () => import('../../dashboard/pages/bookmarks/bookmarks.module').then(m => m.BookmarksModule)
            },
            {
                path: "cvs",
                loadChildren: () => import('../../dashboard/pages/cvs/cvs.module').then(m => m.CVsModule),
                canActivate: [EmployerGuard]
            },
            {
                path: "cv-searches",
                loadChildren: () => import('../../dashboard/pages/cv-searches/cv-searches.module').then(m => m.CvSearchesModule),
                canActivate: [EmployerGuard]
            },
            {
                path: "profile",
                loadChildren: () => import('../../dashboard/pages/profile/profile.module').then(m => m.ProfileModule)
            },
            {
                path: "job-offers",
                loadChildren: () => import('../../dashboard/pages/job-offers/job-offers.module').then(m => m.JobOffersModule),
                canActivate: [JobseekerGuard]
            },
            {
                path: "company",
                loadChildren: () => import('../../dashboard/pages/company/company.module').then(m => m.CompanyModule),
                canActivate: [PrimaryGuard]
            },
            {
                path: "about-us",
                loadChildren: () => import('../../dashboard/pages/about-us/about-us.module').then(m => m.AboutUsModule),
                canActivate: [EmployerGuard]
            },
            {
                path: "",
                redirectTo: "index",
                "pathMatch": "full"
            },
        ]
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: [],
    providers: [
        EmployerGuard,
        PrimaryGuard,
        JobseekerGuard 
    ]
})
export class DashboardRoutingModule { }
