import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs/index";
import {TranslateService} from '@ngx-translate/core';


@Injectable()
export class GlobalService {

  private userDetails = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user') || null));
  userDetailsChanges$ = this.userDetails.asObservable();

  private permissions = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('permissions') || null));
  permissionsChanges$ = this.permissions.asObservable();

  private isLoggedIn = new BehaviorSubject<boolean>(localStorage.getItem('access_token') != null || false);
  loggedInChange$ = this.isLoggedIn.asObservable();

  private isEmployer = new BehaviorSubject<boolean>((localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user')).type == "company") || false);
  isEmployerChange$ = this.isEmployer.asObservable();

  private isJobSeeker = new BehaviorSubject<boolean>((localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user')).type == "jobseeker") || false);
  isJobSeekerChange$ = this.isJobSeeker.asObservable();

  private isAdmin = new BehaviorSubject<boolean>((localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user')).type == "admin") || false);
  isAdminChange$ = this.isAdmin.asObservable();

  private isPrimary = new BehaviorSubject<boolean>((localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user')).primary_account == 1) || false)
  isPrimaryChange$ = this.isPrimary.asObservable();

  private appLanguage = new BehaviorSubject<string>('nl');
  appLanguageChange$ = this.appLanguage.asObservable();

  private showLogin = new BehaviorSubject<boolean>(false);
  showLoginChange$ = this.showLogin.asObservable();

  private showEmployee = new BehaviorSubject<boolean>(false);
  showEmployeeChange$ = this.showEmployee.asObservable();

  private showEmployeeSocial = new BehaviorSubject<boolean>(false);
  showEmployeeSocialChange$ = this.showEmployeeSocial.asObservable();

  private showEmployer = new BehaviorSubject<boolean>(false);
  showEmployerChange$ = this.showEmployer.asObservable();

  private showSignUp = new BehaviorSubject<boolean>(false);
  showSignUpChange$ = this.showSignUp.asObservable();

  private showSideBar = new BehaviorSubject<boolean>(true);
  showSideBar$ = this.showSideBar.asObservable();

  private isImpersonating = new BehaviorSubject<any>(localStorage.getItem('impersonating') != null || false);
  isImpersonatingChange$ = this.isImpersonating.asObservable();

  private hasSeenVideo = new BehaviorSubject<boolean>(localStorage.getItem('intro_video') != null || false);
  hasSeenVideoChange$ = this.hasSeenVideo.asObservable();

  private storedUrl = new BehaviorSubject<string>('/');
  storedUrlChange$ = this.storedUrl.asObservable();

  private openDialog = new BehaviorSubject<boolean>(false);
  openDialogChange$ = this.openDialog.asObservable();


  constructor(private _translate: TranslateService) {
  }

  changeUserDetails = (details) => {
    this.userDetails.next(details);
    if (details) {
      this.changeIsJobSeeker(details.type == 'jobseeker');
      this.changeIsEmployer(details.type == 'company');
      this.changeIsAdmin(details.type == 'admin');
      this.changeIsPrimary(!!details.primary_account)
    } else {
      this.changeIsJobSeeker(false);
      this.changeIsEmployer(false);
      this.changeIsAdmin(false);
      this.changeIsPrimary(false)
    }
  };

  changeLoggedIn = (loggedIn) => {
    this.isLoggedIn.next(loggedIn);
    if (!loggedIn)
      this.changeUserDetails(null);
  };

  getHasAccount = () => {
    return localStorage.getItem('hasAccount') || false;
  };

  changeOpenDialog = (open) => {
    this.openDialog.next(open);
  };

  changeIsPrimary = (isPrimary) => {
    this.isPrimary.next(isPrimary);
  };

  changeIsEmployer = (isEmployer) => {
    this.isEmployer.next(isEmployer);
  };

  changeIsAdmin = (isAdmin) => {
    this.isAdmin.next(isAdmin);
  };

  changeIsJobSeeker = (isJobSeeker) => {
    this.isJobSeeker.next(isJobSeeker);
  };

  changeAppLanguage = (language) => {
    this.appLanguage.next(language);
    this._translate.use(language);
  };

  changeShowLogin = (bool) => {
    this.showLogin.next(bool);
  };

  changeShowEmployeeSocial = (bool) => {
    this.showEmployeeSocial.next(bool);
  };

  changeShowEmployee = (bool) => {
    this.showEmployee.next(bool);
  };

  changeShowEmployer = (bool) => {
    this.showEmployer.next(bool);
  };

  changeShowSignUp = (bool) => {
    this.showSignUp.next(bool);
  };

  changeShowSideBar = (bool) => {
    this.showSideBar.next(bool);
  };

  changeIsImpersonating = (isImpersonating) => {
    this.isImpersonating.next(isImpersonating);
  };

  changePermissions = (permissions) => {
    this.permissions.next(permissions);
  };

  changeHasSeenVideo = (hasSeenVideo) => {
    this.hasSeenVideo.next(hasSeenVideo);
  };

  changeStoredUrl = (url) => {
    this.storedUrl.next(url);
  };

  getLoggedIn = () => {
    return this.isLoggedIn.value;
  };

  getIsAdmin = () => {
    return this.isAdmin.value;
  };

  getIsJobSeeker = () => {
    return this.isJobSeeker.value;
  };

  getIsEmployer = () => {
    return this.isEmployer.value;
  };

  getIsPrimary = () => {
    return this.isPrimary.value;
  };

  getAppLanguage = () => {
    return this.appLanguage.value;
  };

  getUserDetails = () => {
    return this.userDetails.value;
  };

  getPermissions = () => {
    return this.permissions.value;
  };

  getIsImpersonating = () => {
    return this.isImpersonating.value;
  };

  getHasSeenVideo = () => {
    return this.hasSeenVideo.value;
  };

  getStoredUrl = () => {
    return this.storedUrl.value;
  };

}
