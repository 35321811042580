import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {GlobalService} from '../../../_services/global.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

  appLanguage: any;
  isLoggedIn: any;
  user: any;
  currentYear: any;
  featuredCompanies: any;

  constructor(private _globalService: GlobalService) {
  }

  ngOnInit() {
    this.mySubscriptions();
    this.currentYear = (new Date()).getFullYear();
  }

  mySubscriptions = () => {
    this._globalService.loggedInChange$.subscribe(loggedIn => this.isLoggedIn = loggedIn);
    this._globalService.appLanguageChange$.subscribe(language => this.appLanguage = language);
    this._globalService.userDetailsChanges$.subscribe(userDetails => this.user = userDetails);
  }

  showLogin() {
    this._globalService.changeShowLogin(true);
  }

  showEmployee() {
    this._globalService.changeShowEmployee(true);
  }

  showEmployer() {
    this._globalService.changeShowEmployer(true);
  }
}
