import {Injectable, OnInit} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {GlobalService} from "../../_services/global.service";

@Injectable()
export class StateGuard implements CanActivate, OnInit {

    constructor(private _router: Router, private _globalService: GlobalService) {

    }

    ngOnInit() {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this._globalService.getLoggedIn()) {
            this._router.navigate(['/' + this._globalService.getAppLanguage()]);
        } else {
            return true;
        }
        return false;
    }
}