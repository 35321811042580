import {AfterContentInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterContentInit{

  @Input() autoFocusEnabled = true;
  @Input() autoFocusPreventScroll = false;

  constructor(private el: ElementRef) {
  }

  ngAfterContentInit(): void {
    if (this.autoFocusEnabled) {
      this.el.nativeElement.focus({preventScroll: this.autoFocusPreventScroll});
    }
  }

}
