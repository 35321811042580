import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError} from "rxjs/internal/operators";
import {Helpers} from "../../../helpers";

@Injectable()
export class IndexService {

    url: string;

    constructor(private _http: HttpClient) {
    }

    getStatistics = (language?: string, year?: number, month?: number) => {
        this.url = '/api/dashboard/statistics';
        if (language) {
            this.url += '/' + language;
        }
        this.url +=  '?year=' + year + '&month=' + month;
        return this._http.request<any>('GET', this.url).pipe(
            catchError(Helpers.handleError)
        );
    }

    getVacancies = (language?: string) => {
      this.url = '/api/dashboard/jobseeker/vacancies';
      if (language) {
        this.url += '/' + language;
      }
      return this._http.request<any>('GET', this.url).pipe(
        catchError(Helpers.handleError)
      );
    }

    apply = (slug: string, language?: string) => {
      let url = '/api/dashboard/jobseeker/vacancies/apply/' + slug;
      if (language) {
        url += '/' + language;
      }
      return this._http.request<any>('POST', url, {body: []}).pipe(
        catchError(Helpers.handleError)
      );
    }
}
