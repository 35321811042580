import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {RouterModule} from '@angular/router';
import {MasterComponent} from './master/master.component';

import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConsentComponent} from './_components/cookie-consent/consent.component';
import {SharedModule} from 'src/app/_shared/shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import {ProfileService} from 'src/app/dashboard/pages/profile/profile.service';
import {VideoPopupComponent} from './_components/video-popup/video-popup.component';
import {FooterService} from './footer/footer.service';
import {AnimateOnScrollModule} from "ng2-animate-on-scroll";
import {RegisterEmployerStepFormComponent} from "./_components/register-employer-step-form/register-employer-step-form.component";
import {RegisterEmployeeStepFormComponent} from "./_components/register-employee-step-form/register-employee-step-form.component";
import {MatStepperModule} from "@angular/material/stepper";
import {NgSelectModule} from "@ng-select/ng-select";
import {CvService} from "../../dashboard/pages/profile/cv/cv.service";
import {CompanyService} from "../../dashboard/pages/company/company.service";
import {IndexService} from "../../dashboard/pages/index/index.service";
import { RegisterEmployeeSocialStepFormComponent } from './_components/register-employee-social-step-form/register-employee-social-step-form.component';
import {LoadScreenModule} from "../_shared/components/load-screen/load-screen.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatTooltipModule,
        AnimateOnScrollModule,
        MatStepperModule,
        NgSelectModule,
        LoadScreenModule,
    ],
  declarations: [
    MasterComponent,
    FooterComponent,
    HeaderComponent,
    ConsentComponent,
    VideoPopupComponent,
    RegisterEmployerStepFormComponent,
    RegisterEmployeeStepFormComponent,
    RegisterEmployeeSocialStepFormComponent
  ],
  exports: [
    MasterComponent,
    HeaderComponent,
    FooterComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  providers: [
    ProfileService,
    FooterService,
    CvService,
    CompanyService,
    IndexService
  ]
})
export class LayoutModule {
}
