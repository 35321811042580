import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-circular-progress-bar',
  templateUrl: './circular-progress-bar.component.html',
  styleUrls: ['./circular-progress-bar.component.css']
})
export class CircularProgressBarComponent implements OnInit {

  @Input() totalAmount: number;
  @Input() remainingAmount: number;
  @Input() color: string;

  percentage: number;

  constructor() { }

  ngOnInit() {
    this.calculatePercentage();
  }

  calculatePercentage() {
    if (this.totalAmount === -1) {
      this.percentage = 100;
    } else {
      this.percentage = Math.round(this.remainingAmount / this.totalAmount * 100);
    }
  }
}
