import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { catchError, tap } from "rxjs/internal/operators";
import { Observable, of, throwError } from "rxjs";
import { GlobalService } from "../_services/global.service";
import { Globals } from "../_globals/globals";
import { environment } from "../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _globalService: GlobalService,
    private _globals: Globals
  ) {}

  renewToken(event, url) {
    if (url.indexOf("/api/") === 0) {
      if (event.headers.get("authorization") != null) {
        let token = event.headers.get("authorization").replace("Bearer ", "");
        localStorage.setItem("access_token", token);
      } else if (url.indexOf("search-profile") > -1) {
        console.log("do nothing, authorization should be exposed");
      } else if (this._globalService.getLoggedIn()) {
        this.removeImpersonationData();
        localStorage.removeItem("access_token");
        this._globalService.changeLoggedIn(false);
        this._router.navigate([
          this._globalService.getAppLanguage() + "/login",
        ]);
      }
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let user = JSON.parse(localStorage.getItem("user"));
    let access_token = localStorage.getItem("access_token");
    let authReq;
    let url = req.url;
    if (url.indexOf("/api/") === 0 && environment.testing) {
      req = req.clone({ url: "https://test.bouwjobs.be" + req.url });
    } else if (url.indexOf("/api/") === 0 && environment.alpha) {
      req = req.clone({ url: "https://api-new.bouwjobs.be" + req.url });
    }
    if (user && access_token && url.indexOf("/api/") === 0) {
      const authHeader = "Bearer " + access_token;
      authReq = req.clone({
        setHeaders: {
          Authorization: authHeader,
          "O-Referrer": this._globals.getReferrer(),
        },
      });
    } else {
      authReq = req.clone({
        setHeaders: { "O-Referrer": this._globals.getReferrer() },
      });
    }
    return next
      .handle(authReq)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.renewToken(event, url);
          }
        })
      )
      .pipe(
        catchError((err) => {
          if (err.status == 401) {
            this.removeImpersonationData();
            localStorage.removeItem("access_token");
            this._globalService.changeLoggedIn(false);
            this._router.navigate([
              this._globalService.getAppLanguage() + "/login",
            ]);
          } else if (err.status == 404) {
            this.renewToken(err, url);
            const index = url.indexOf("/api/search-profile");
            if (index < 0) {
              this._router.navigate([
                this._globalService.getAppLanguage() + "/404",
              ]);
            }
          } else if (err instanceof HttpErrorResponse) {
            this.renewToken(err, url);
          }
          // Helpers.errorMessage(err.error.message);
          return throwError(err.error);
        })
      );
  }

  removeImpersonationData() {
    if (localStorage.getItem("impersonating")) {
      localStorage.removeItem("impersonating");
      this._globalService.changeIsImpersonating(false);
    }
  }
}
