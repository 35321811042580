import {Injectable, OnInit} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from "@angular/router";
import {GlobalService} from "../_services/global.service";

@Injectable()
export class NotAdminGuard implements CanActivate, OnInit {

    constructor(private _router: Router, private _globalService: GlobalService) {

    }

    ngOnInit() {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this._globalService.getIsAdmin() && this._globalService.getLoggedIn()) {
            return true;
        } else {
            this._router.navigate(['/' + this._globalService.getAppLanguage() + '/login']);
        }
        return false;
    }
}